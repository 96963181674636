import Tooltip from "../../components/shared/Tooltip";

export default function ChipsList(prop: { list: any; att: any }) {
  let tool = "";
  prop.list.forEach((el: any, index: number) => {
    tool =
      tool + `${index > 0 ? ", " : ""} ${el[prop.att]?.data?.attributes?.name}`;
  });

  const truncateName = (name: string) => {
    if (name) {
      if (name.length > 6) {
        return name.substring(0, 6) + "...";
      }
      return name;
    } else {
      return "---";
    }
  };

  if (prop.list && prop.list[0]?.[prop.att]?.data?.attributes?.name === "N/A")
    return <span>N/A</span>;

  return prop.list.length > 0 ? (
    <Tooltip text={tool}>
      <div className="text-skin-focus text-center text-[13px] capitalize text-[#717171] h-[17px] flex flex-col justify-center items-center font-light truncate cursor-pointer rounded-xl border-[1px] bg-[#ece7e7] w-auto px-1">
        {prop.list.length === 1 ? (
          <div>
            {truncateName(prop.list[0][prop.att]?.data?.attributes?.name)}
          </div>
        ) : (
          <div>
            {truncateName(prop.list[0][prop.att]?.data?.attributes?.name)} +
            {" aaa"}
            {prop.list.length - 1}
          </div>
        )}
      </div>
    </Tooltip>
  ) : (
    <></>
  );
}
